import Head from 'next/head';
import { Box } from '@mui/material';
import { getCookie } from 'cookies-next';

import { MediaPress } from 'templates/PlansTemplate/imports';

import {
  Corporate,
  FluencyJourney,
  Footer,
  Header,
  Hero,
  LifeMoment,
  Testimonies,
} from 'templates/HomeTemplate/components';

import { HomeMenuNav, HomeTabs } from 'mocks/menuNav';
import { testimoniesHomeFull } from 'mocks/testimonies';

import { groupURL, cicleURL } from 'utils/constants/homepageImages';

export async function getServerSideProps({ req, res }) {
  const runningInWebView = getCookie('runningInWebView', { req, res });

  // eslint-disable-next-line no-new-wrappers
  if (new Boolean(runningInWebView).valueOf() === true) {
    return {
      redirect: {
        permanent: false,
        destination: '/account',
      },
    };
  }

  return {
    props: {},
  };
}

export default function HomePage() {
  return (
    <Box component="main">
      <Head>
        <meta
          content="Fluencypass - O único ciclo completo da fluência"
          key="title"
          property="og:title"
        />
        <meta
          content="Estude inglês desde já com opção de intercâmbio no final, ou simplesmente faça seu intercâmbio."
          key="description"
          property="og:description"
        />
      </Head>

      <Header
        menuNavHeader={HomeMenuNav}
        menuNavSideMenu={HomeMenuNav}
        tabs={HomeTabs}
      />

      <Hero blurImages={{ groupURL, cicleURL }} />

      <MediaPress />

      <FluencyJourney hideCTA />

      <LifeMoment />

      <Corporate />

      <Testimonies testimonies={testimoniesHomeFull} />

      <Footer />
    </Box>
  );
}
