import Dynamic from 'next/dynamic';

const Header = Dynamic(() => import('components/Shared/Header'), {
  ssr: true,
});

const Hero = Dynamic(() => import('components/HomeComponents/Hero'), {
  ssr: true,
});

const LifeMomentSkeleton = Dynamic(
  () => import('components/HomeComponents/LifeMoment/Skeleton'),
  {
    ssr: true,
  }
);

const TestimoniesSkeleton = Dynamic(
  () => import('components/HomeComponents/Testimonies/Skeleton'),
  {
    ssr: true,
  }
);

const CorporateSkeleton = Dynamic(
  () => import('components/HomeComponents/Corporate/Skeleton'),
  {
    ssr: true,
  }
);

const FluencyJourney = Dynamic(
  () => import('components/Shared/FluencyJourney'),
  {
    ssr: true,
  }
);

const LifeMoment = Dynamic(
  () => import('components/HomeComponents/LifeMoment'),
  {
    ssr: false,
    loading: () => <LifeMomentSkeleton />,
  }
);

const Corporate = Dynamic(() => import('components/HomeComponents/Corporate'), {
  ssr: false,
  loading: () => <CorporateSkeleton />,
});

const Testimonies = Dynamic(
  () => import('components/HomeComponents/Testimonies'),
  {
    ssr: false,
    loading: () => <TestimoniesSkeleton />,
  }
);

const Footer = Dynamic(() => import('components/Shared/Footer'), {
  ssr: true,
});

export {
  Corporate,
  FluencyJourney,
  Footer,
  Header,
  Hero,
  LifeMoment,
  Testimonies,
};
